import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Guid } from "guid-typescript";
import * as hash from "object-hash";
import { Observable, Subscription, fromEvent } from "rxjs";
import { map } from "rxjs/operators";
import { aabbOfPolyline } from "webcad/models";
import { BasicPlateParams } from "../../../../../../src/app/model/basic-templates.model";
import {
  Element,
  ElementType,
} from "../../../../../../src/app/model/product-configuration/element.model";
import {
  ShapeWithHoles,
  createRectangle,
} from "../../../../../../src/app/model/shape-with-holes";
import { UserTemplate } from "../../../../../../src/app/model/user-template.model";
import {
  ChangeTemplateModal,
  MevacoState,
  ProductConfigurationAddElement,
  getBasicRectangleTemplate,
  getUserTemplates,
} from "../../../../../../src/app/store";
import {
  SetBasicA,
  SetBasicB,
  SetBasicE1,
  SetBasicE2,
  SetBasicF1,
  SetBasicF2,
  SetBasicRectangleTemplate,
} from "../../../../../../src/app/store/actions/element-creator.actions";
import { GetUserTemplates } from "../../../../../../src/app/store/actions/user-template.actions";
import { reqValBasicRectangle } from "../../../../store/reducers";
import { roundDecimal } from "../../../../utils/utils";
import { TemplateName } from "../../template-name";
import { TemplateUtils } from "../../templatesUtils";

@Component({
  selector: "mevaco-basic-rectangle",
  templateUrl: "./basic-rectangle.component.html",
  styleUrls: [
    "../../../element-creator/element-creator.component.css",
    "./basic-rectangle.component.css",
  ],
})
export class BasicRectangleComponent implements OnInit {
  @ViewChild("a", { static: true }) a: ElementRef;
  @ViewChild("e1", { static: true }) e1: ElementRef;
  @ViewChild("e2", { static: true }) e2: ElementRef;
  @ViewChild("b", { static: true }) b: ElementRef;
  @ViewChild("f1", { static: true }) f1: ElementRef;
  @ViewChild("f2", { static: true }) f2: ElementRef;
  @ViewChild("closeButton", { static: true })
  closeButton: ElementRef;
  @Output() dismissModal = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @Output() isModalOpen = new EventEmitter();

  public roundDecimal = roundDecimal;
  public basicRectangleTemplate: BasicPlateParams;
  public templates: Observable<UserTemplate[]>;
  public reqValBasicRectangle: Observable<boolean>;
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<MevacoState>,
    public templateUtils: TemplateUtils
  ) {}

  ngOnInit() {
    this.store.dispatch(new GetUserTemplates());
    this.templates = this.store.pipe(select(getUserTemplates));
    this.subscriptions.push(
      this.store.pipe(select(getBasicRectangleTemplate)).subscribe((v) => {
        this.basicRectangleTemplate = { ...v };
      })
    );
    this.reqValBasicRectangle = this.store.pipe(select(reqValBasicRectangle));
    this.createElementFromBasicRectangleTemplate();
  }

  isModalOpenMethod() {
    this.isModalOpen.emit(false);
  }

  createElementFromBasicRectangleTemplate() {
    const obs = fromEvent(this.closeButton.nativeElement, "click").pipe(
      map(() => {
        return {
          ...this.basicRectangleTemplate,
        };
      })
    );

    obs.subscribe((result) => {
      const a = result.a / 1000;
      const b = result.b / 1000;
      const e1 = result.e1 / 1000;
      const e2 = result.e2 / 1000;
      const f1 = result.f1 / 1000;
      const f2 = result.f2 / 1000;
      const contures = createRectangle({ x: 0, y: 0 }, { x: b, y: a });
      const aabb = aabbOfPolyline(contures);
      const area = b * a;
      const shape: ShapeWithHoles = {
        conture: contures,
        holes: [],
        aabb: aabb,
        area: area,
      };
      shape.hash = hash(shape.aabb) + hash(shape.area);
      const perfMin = { x: f1, y: e1 };
      const perfMax = { x: b - f2, y: a - e2 };
      const perforationArea = createRectangle(perfMin, perfMax);
      const perfAabb = aabbOfPolyline(perforationArea);
      const perfArea = (perfMax.x - perfMin.x) * (perfMax.y - perfMin.y);
      const perforationAreaShape: ShapeWithHoles = {
        conture: perforationArea,
        holes: [],
        aabb: perfAabb,
        area: perfArea,
      };
      perforationAreaShape.hash = hash(perforationAreaShape);
      const element: Element = {
        type: ElementType.individual,
        templateName: TemplateName.basicRectangle,
        boundary: null,
        position: -1, // will be set inb reducer
        quantity: 1,
        a: a,
        b: b,
        aIst: a,
        bIst: b,
        e1: e1.toString(),
        e1ist: e1.toString(),
        e2: e2.toString(),
        e2ist: e2.toString(),
        f1: f1.toString(),
        f1ist: f1.toString(),
        f2: f2.toString(),
        f2ist: f2.toString(),
        openMeshE: "",
        openMeshF: "",
        toleranceWidth: 0,
        toleranceLength: 0,
        label: "",
        note: "",
        unperforated: false,
        posibleCoil: "No",
        posiblePlate: "No",
        shapes: null,
        nodes: null,
        verticesIndexes: null,
        visualizationShape: null,
        previewImageId: Guid.create().toString(),
        shape: shape,
        perforationAreas: [
          {
            shape: perforationAreaShape,
            rotation: 0,
            offset: { x: 0, y: 0 },
          },
        ],
        helpLines: [],
        measurements: [],
        angleMeasurements: [],
        mountings: [],
        perforationAutoCenter: true,
        area: shape.area,
        minRadius: null,
        minParallelEdgesDistance: null,
        minMarginDistance: null,
        isPerforationSimpleRect: null,
        isShapeSimpleRect: null,
        minMountingHoleEdgeDistance: null,
        minMountingHolesDistance: null,
        minOutsideAngle: null,
        minDistanceBetweenMountingAndPerforatedArea: null,
        minDistanceBetweenEdges: null,
        numberOfArcs: null,
        //perforation: null,
        possibleAllAcross: true,
        minDistanceBetweenPerforationAreas: null,
        lfbIst: undefined,
        lflIst: undefined,
        breakLines: [],
        bendingLinesDistances: [],
        //possiblePerforationZones: []
      };
      this.store.dispatch(new ProductConfigurationAddElement(element));
      this.store.dispatch(new ChangeTemplateModal(null));
    });
  }

  setBasicPlateTemplate(template: BasicPlateParams) {
    this.store.dispatch(new SetBasicRectangleTemplate({ ...template }));
  }

  setBasicA(newA: any) {
    this.store.dispatch(new SetBasicA(newA));
  }

  setBasicE1(newE1: any) {
    this.store.dispatch(new SetBasicE1(newE1));
  }

  setBasicE2(newE2: any) {
    this.store.dispatch(new SetBasicE2(newE2));
  }

  setBasicB(newB: any) {
    this.store.dispatch(new SetBasicB(newB));
  }

  setBasicF1(newF1: any) {
    this.store.dispatch(new SetBasicF1(newF1));
  }

  setBasicF2(newF2: any) {
    this.store.dispatch(new SetBasicF2(newF2));
  }

  dismiss(str: string) {
    this.store.dispatch(new ChangeTemplateModal(null));
    this.dismissModal.emit(str);
  }

  close(input: {}) {
    this.store.dispatch(new ChangeTemplateModal(null));
    this.closeModal.emit(input);
  }
}
