export interface SnapOptions {
  grid: boolean;
  helpLines: boolean;
  nodes: boolean;
  edges: boolean;
  measurements: boolean;
  mountingHoles: boolean;
  perforation: boolean;
  perforationCollider: boolean;
  perforationArea: boolean;
  import: boolean;
  mountingHolesOutline: boolean;
  bendingLines: boolean;
}

export const basicSnapOptions: SnapOptions = {
  edges: true,
  grid: false,
  helpLines: true,
  nodes: true,
  measurements: true,
  mountingHoles: true,
  perforation: false,
  perforationCollider: false,
  perforationArea: false,
  import: false,
  mountingHolesOutline: true,
  bendingLines: true
};
