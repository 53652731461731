import {PointNode} from './point-node.model';
import {initialPlate, Plate} from './plate.model';
import {initialSnappingModel, SnappingModel} from './snapping.model';
import {HelpLine} from './help-line.model';
import {basicSnapOptions, SnapOptions} from './snap-options.model';
import {AngleMeasurementModel, MeasurementModel, Segment} from 'webcad/models';
import {MountingAttachment, MountingModel, MountingType} from './mounting.model';
import {Vector2} from 'webcad';
import {HintModel, INITIAL_HINT_MODEL} from './hint.model';
import {ToolModel} from './tool';
import {PatternGrid} from "./product-configuration/element.model";

export enum ShapeOrigin {
  SHAPE,
  PERFORATION,
  TEMPORARY
}

export interface SegmentAndOrigin {
  segment: Segment;
  origin: ShapeOrigin;
}

export interface Drawing {
  nodes: PointNode[];
  plate: Plate;
  dependentMode: boolean;
  lastDrawnLineId: number;
  snappingModel: SnappingModel;
  selectedShapeId: number;
  selectedNodes: PointNode[];
  helpingLines: Map<number, HelpLine>;
  toolHelpLines: Map<number, HelpLine>;
  snapOptions: SnapOptions;
  closestSegmentVisibility: boolean;
  hint: HintModel;
  automaticMeasurements: Map<number, MeasurementModel>;
  removedMeasurements: MeasurementModel[];
  measurements: Map<number, MeasurementModel>;
  angleMeasurements: Map<number, AngleMeasurementModel>;
  selectedMeasurementId: number;
  selectedAutomaticMeasurementId: number;
  selectedAngleMeasurementId: number;
  selectedHelpLineId: number;
  mountingsModel: MountingModel;
  selectedMountings: MountingAttachment[];
  importedShape: Segment[][];
  importId?: number;
  isImporting: boolean;
  showPerforationBorder: boolean;
  showExpandedMetalShape: 'shape' | 'cut' | undefined;
  fillPerforation: boolean;
  selectedPerforationArea: number;
  hintOption: string;
  tool?: ToolModel;
  flat: boolean;
  patternGrid?: PatternGrid[];
  toleranceWidth?: number;
  toleranceLength?: number;
}

export const initialDrawing: Drawing = {
  removedMeasurements: [],
  nodes: [],
  plate: initialPlate,
  lastDrawnLineId: -1,
  snappingModel: initialSnappingModel,
  selectedShapeId: -1,
  selectedNodes: [],
  helpingLines: new Map<number, HelpLine>(),
  toolHelpLines: new Map<number, HelpLine>(),
  snapOptions: basicSnapOptions,
  closestSegmentVisibility: true,
  hint: INITIAL_HINT_MODEL,
  measurements: new Map<number, MeasurementModel>(),
  angleMeasurements: new Map<number, AngleMeasurementModel>(),
  selectedMeasurementId: null,
  selectedAutomaticMeasurementId: null,
  selectedAngleMeasurementId: null,
  selectedHelpLineId: null,
  selectedPerforationArea: -1,
  mountingsModel: {
    mounting: {
      active: null,
      extensionX: null,
      extensionY: null,
      form: null,
      id: null,
      length: null,
      materialCode: null,
      positioning: null,
      rotateable: null,
      thicknessMax: null,
      thicknessMin: null,
      width: null,
    }, verticesMap: new Map<MountingType, Vector2[]>(),
    shapeMap: new Map<MountingType, Segment[]>()
  },
  isImporting: false,
  selectedMountings: [],
  importedShape: [],
  showPerforationBorder: false,
  showExpandedMetalShape: undefined,
  hintOption: 'Enabled',
  automaticMeasurements: new Map<number, MeasurementModel>(),
  dependentMode: true,
  flat: false,
  fillPerforation: false
};
