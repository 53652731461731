import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store, select } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
} from "rxjs";
import { environment } from "../../../environments/environment";
import { MevacoPage } from "../../model/mevaco-page.model";
import { Element } from "../../model/product-configuration/element.model";
import { ProductConfiguration } from "../../model/product-configuration/product-configuration.model";
import { PreviewPlateProvider } from "../../providers/preview-plate.provider";
import { ReloadPositionProvider } from "../../providers/reload-position.provider";
import { TranslationProvider } from "../../providers/translation.provider";
import { PreviewImageService } from "../../services/preview-image.service";
import { ProductConfigurationService } from "../../services/product-configuration.service";
import {
  CopyPosition,
  PreviewSuccess,
  ProductConfigurationEditElement,
  ProductConfigurationRemoveElement,
  SetNote,
  SetQuantity,
} from "../../store/actions";
import { AddUserTemplate } from "../../store/actions/user-template.actions";
import {
  MevacoState,
  elementHasErrors,
  getElementWithId,
  getMevacoPageState,
  getProductConfiguration,
  needElementUpdate,
} from "../../store/reducers";

@Component({
  selector: "app-positions-item",
  templateUrl: "./positions-item.component.html",
  styleUrls: ["./positions-item.component.css"],
})
export class PositionsItemComponent implements OnInit, OnDestroy {
  @ViewChild(".bottom-index-element", { static: true })
  pieceInput: HTMLInputElement;
  @Input()
  public model: Element;
  public isChecked: Observable<boolean>;
  private photo: Subject<string>;
  private image: Subject<any>;
  private subsciptions: Subscription[] = [];
  public imageReady = false;
  public getImageUrl: BehaviorSubject<string> = new BehaviorSubject("");
  private isInQueue = false;
  public area: string;
  public toFixed = Number.prototype.toFixed;
  public show = false;
  public show2 = false;
  public projectName: string = this.translate("projectName");
  public hasErrors: Observable<boolean>;
  public configuration: ProductConfiguration;

  constructor(
    private store: Store<MevacoState>,
    private previewBuilder: PreviewPlateProvider,
    private modalService: NgbModal,
    private translationProvider: TranslationProvider,
    private previewImageService: PreviewImageService,
    private reloadPositionProvider: ReloadPositionProvider,
    private productConfigurationService: ProductConfigurationService,
    private toastr: ToastrService
  ) {
    this.photo = new Subject<string>();
    this.image = new Subject<any>();
  }

  ngOnInit() {
    this.subsciptions.push(
      this.store
        .pipe(select(getProductConfiguration))
        .subscribe((c) => (this.configuration = c))
    );
    this.area = this.model.area ? this.model.area.toFixed(4) : "0";
    const id = this.model.previewImageId || null;
    this.getImageUrl.next(
      environment.api_url + "/api/mevaco/getPreviewImage/" + id + "?" + getVersion(id)
    );
    this.subsciptions.push(
      this.photo.asObservable().subscribe((data) => {
        ///here to check
        this.setImageNotReady();
        this.getImageUrl.next("");
        const dataToSend = data.replace("data:image/png;base64,", "");
        console.log("before preview image service");
        this.previewImageService
          .saveImage({
            ImageData: dataToSend,
            Guid: this.model.previewImageId,
          })
          .subscribe((v) => {
            console.log("post preview image service");
            console.log(
              "Prechange: " + this.model.position + " " + this.getImageUrl.value
            );
            this.getImageUrl.next(
              environment.api_url +
                "/api/mevaco/getPreviewImage/" +
                id +
                "?" +
                updateVersion(id)
            );
            console.log(
              "Postchange: " +
                this.model.position +
                " " +
                this.getImageUrl.value
            );
            this.store.dispatch(new PreviewSuccess(this.model.position));
            this.isInQueue = false;
            this.reloadPositionProvider.unregister(this.model.position);
          });
      })
    );
    this.subsciptions.push(
      combineLatest([
        //this.store.pipe(select(isPerforationConfigurationReady)),
        this.store.pipe(select(getElementWithId(this.model.position))),
        this.store.pipe(select(needElementUpdate(this.model.position))),
      ]).subscribe(([v, el]) => {
        if (v && el) {
          if (
            !this.isInQueue &&
            !this.reloadPositionProvider.checkPositionState(this.model.position)
          ) {
            this.store
              .pipe(select(getMevacoPageState))
              .subscribe((mevacoPageState: MevacoPage) => {
                const state: MevacoPage = {
                  ...mevacoPageState,
                  drawing: {
                    ...mevacoPageState.drawing,
                    showPerforationBorder: false,
                    showExpandedMetalShape: undefined,
                    fillPerforation: false,
                    snapOptions: {
                      ...mevacoPageState.drawing.snapOptions,
                      edges: false,
                      grid: false,
                      helpLines: false,
                      nodes: false,
                      measurements: false,
                      perforation: true,
                      perforationArea: false,
                      mountingHoles: true,
                      perforationCollider: false,
                      import: false,
                      mountingHolesOutline: false,
                    },
                  },
                } as MevacoPage;
                this.reloadPositionProvider.register(this.model.position);
                this.previewBuilder.addToSnapshotQueue(
                  this.photo,
                  state,
                  this.model.position
                ); //this inits to late
                this.isInQueue = true;
                this.setImageNotReady();
              })
              .unsubscribe();
          }
        }
      })
    );
    this.isChecked = this.store.pipe(
      select((state) => state.model.productConfiguration.generateLabel)
    );
    this.hasErrors = this.store.pipe(
      select(elementHasErrors(this.model.position))
    );
  }

  ngOnDestroy(): void {
    this.previewBuilder.removeFromSnapshotQueue(this.photo);
    this.unsubscribeAll();
    this.reloadPositionProvider.unregister(this.model.position);
  }

  unsubscribeAll(): void {
    for (const s of this.subsciptions) {
      if (!s.closed) {
        s.unsubscribe();
      }
    }
    this.subsciptions = [];
  }

  setImageReady(): void {
    this.imageReady = true;
  }

  setImageNotReady(): void {
    this.imageReady = false;
  }

  async delete() {
    this.reloadPositionProvider.unregister(this.model.position);
    this.unsubscribeAll();
    if (this.model && this.model.previewImageId) {
      const ret = await this.previewImageService
        .deleteImage(this.model.previewImageId)
        .toPromise();
    }
    // this.store.dispatch(new ResetDrawing());
    this.store.dispatch(
      new ProductConfigurationRemoveElement(this.model.position)
    );
    console.log("position-item-list");
    this.productConfigurationService.save(this.configuration);
  }

  edit() {
    this.store.dispatch(
      new ProductConfigurationEditElement(this.model.position)
    );
  }

  makeTemplate() {
    this.store.dispatch(
      new AddUserTemplate({
        id: this.model.position,
        name: this.projectName,
      })
    );
    this.show2 = !this.show2;
    this.toastr.success(this.translate("Saved successfully"));
  }

  makeTemplateOpen() {
    this.show2 = !this.show2;
  }

  // confirmPopup(content: any) {
  //   this.modalService.open(content, {centered: true}).result.then((result: boolean) => {
  //     if (result) {
  //       this.delete();
  //     }
  //   }, (reason) => {

  //   });
  // }

  confirmOpen() {
    this.show = !this.show;
  }

  onNoteChange(value: string) {
    const text = value.trim();
    this.store.dispatch(
      new SetNote({ elementId: this.model.position, note: text })
    );
  }

  onQuantityChange(value: string) {
    this.store.dispatch(
      new SetQuantity({
        elementId: this.model.position,
        quantity: Number(value),
      })
    );
  }

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }

  copyPosition() {
    this.store.dispatch(new CopyPosition(this.model.position));
  }
}

const versions: {[key: string]: number} = {};
function getVersion(id: string): string {
  return (versions[id] || 0).toString();
}

function updateVersion(id: string): string {
  let v = versions[id] || 0;
  v++;
  versions[id] = v;
  return v.toString();
}
