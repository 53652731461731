import { ModelVisualizer, ViewModelVisualizer } from "webcad";
import { LabelHtml } from "webcad/html-layer/label-html";
import { Drawing } from "../model/drawing.model";
import { AngleMeasurementsViewModelPipeLine } from "../model/view-model/angle-measurements.viewModel";
import { BendingLinesViewModelPipeLine } from "../model/view-model/bending-lines.viewModel";
import { HelpLinesViewModelPipeLine } from "../model/view-model/help-lines.viewModel";
import { MeasurementsViewModelPipeLine } from "../model/view-model/measurements.viewModel";
import { PerforationAreaViewModel } from "../model/view-model/perforation-area.viewModel";
import { SnappingGridViewModelPipeline } from "../model/view-model/snapping-grid-visualizer.viewModel";
import { PlateMeshProvider } from "../providers/plate-mesh.provider";
import { AngleMeasurementsVisualizer } from "./angle-measurements.visualizer";
import { BendingLinesVisualizer } from "./bending-lines.visualizer";
import { HelpLinesVisualizer } from "./help-lines.visualizer";
import {
  ImportVisualizer,
  ImportVisualizerPipeline,
} from "./import.visualizer";
import { MeasurementsVisualizer } from "./measurements.visualizer";
import { MountingsVisualizer } from "./mountings.visualizer";
import { PerforationAreaVisualizer } from "./perforation-area.visualizer";
import { PlateVisualizer } from "./plate.visualizer";
import { PolylineVisualizer } from "./polyline.visualizer";
import { SnappingGridVisualizer } from "./snapping-grid.visualizer";
import { ToolVisualizer } from "./tool.visualizer";
import { BendingTreeProvider } from "./viewmodel-provifers/bending-tree.provider";
import { BoundaryViewModelProvider } from "./viewmodel-provifers/boundary-view-model-provider";
import { MountingsViewModelProvider } from "./viewmodel-provifers/mountings-viewModelProvider";
import { PlateVisualizerViewModelProvider } from "./viewmodel-provifers/plate-viewModelProvider";
import {ExpmetCutModelProvider, ExpmetDesiredShapeModelProvider} from "./viewmodel-provifers/expmet-cut-model.provider";

export class DrawingVisualizer extends ViewModelVisualizer<Drawing> {
  constructor(
    plateMeshProvider: PlateMeshProvider,
    bendingTreeProvider: BendingTreeProvider
  ) {
    const plateVisualizerViewModelProvider =
      new PlateVisualizerViewModelProvider(bendingTreeProvider);
    const mountingsViewModelProvider = new MountingsViewModelProvider(
      bendingTreeProvider
    );
    const boundaryViewModelProvider = new BoundaryViewModelProvider();
    const expMetCutViewModelProvider = new ExpmetCutModelProvider();
    const expMetDesiredShapeViewModelProvider = new ExpmetDesiredShapeModelProvider();


    super(
      new Map<ModelVisualizer<any>[], (model: Drawing) => Object>([
        [[new SnappingGridVisualizer()], SnappingGridViewModelPipeline],
        [
          [new PerforationAreaVisualizer()],
          (model: Drawing): PerforationAreaViewModel => {
            return {
              perforationAreas: model.plate.perforationAreas,
              plateDepth: model.plate.depth,
              showPerforationAreaOutline: model.snapOptions.perforationArea,
              selectedAreaIndex: model.selectedPerforationArea,
              dependentMode: model.dependentMode,
            };
          },
        ],
        [
          [new PlateVisualizer(plateMeshProvider)],
          plateVisualizerViewModelProvider.getViewModel.bind(
            plateVisualizerViewModelProvider
          ),
        ],
        [[new BendingLinesVisualizer()], BendingLinesViewModelPipeLine],
        [[new HelpLinesVisualizer()], HelpLinesViewModelPipeLine],
        [[new MeasurementsVisualizer()], MeasurementsViewModelPipeLine],
        [
          [new AngleMeasurementsVisualizer()],
          AngleMeasurementsViewModelPipeLine,
        ],
        [
          [new MountingsVisualizer()],
          mountingsViewModelProvider.getViewModel.bind(
            mountingsViewModelProvider
          ),
        ],
        [[new LabelHtml()], (model: Drawing) => model.hint.label],
        [[new ImportVisualizer()], ImportVisualizerPipeline],
        [[new ToolVisualizer()], (model) => model.tool],
        [
          [new PolylineVisualizer()],
          boundaryViewModelProvider.getViewModel.bind(
            boundaryViewModelProvider
          ),
        ],
        [
          [new PolylineVisualizer()],
          expMetCutViewModelProvider.getViewModel.bind(
            expMetCutViewModelProvider
          ),
        ],
        [
          [new PolylineVisualizer()],
          expMetDesiredShapeViewModelProvider.getViewModel.bind(
            expMetDesiredShapeViewModelProvider
          ),
        ],
      ])
    );
  }
}
