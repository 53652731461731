<ng-container *ngIf="(mode$|async); let mode">
  <div *ngIf="(step | async) === Step.design || mode ===' view'" class="topbar-container">
    <div class="dropdown-container" *ngIf="mode !== 'view'">
      <dropdown [model]="hints" [tooltipConfig]="{fieldName:'hints'}" [dropdownMenuWidth]="hintsMenuWidth"></dropdown>
    </div>
      <div class="options options--grow" [class.options--view]="mode === 'view'">
        <div *ngIf="mode === 'view'">
          <img class="logo" [src]="(logoUrl|async)" />
        </div>
        <mat-checkbox class="base-checkbox topbar-checkbox" id="MeasurementDesignStatus" [checked]="measurementStatus | async" (change)="onMeasurementStatusChange($event)">
        {{translate("Measurements")}}
        </mat-checkbox>
      </div>
      <div *ngIf="mode === 'view'" class="zoom-container">
        <button (mousedown)="zoomOut()" (mouseup)="stopAction()" (mouseleave)="stopAction()" class="zoom-btn">
          <img src="assets/Zoom_minus.svg" alt="minus">
        </button>
        <button (mousedown)="zoomIn()" (mouseup)="stopAction()" (mouseleave)="stopAction()" class="zoom-btn margin-left-5">
          <img src="assets/Zoom_plus.svg" alt="plus">
        </button>
        <button type="button" (click)="zoomToFit()" class="fit-zoom-btn">
          {{translate("Fit Zoom")}}
        </button>
      </div>

      <color-picker></color-picker>
    <div class="ml-20" *ngIf="mode === 'view'">
      <view-switch></view-switch>
    </div>
  </div>


  <div *ngIf="(step | async) !== Step.design && mode !== 'view'" class="topbar-container">
    <div class="dropdown-container">
      <dropdown [model]="hints" [tooltipConfig]="{fieldName:'hints'}" [dropdownMenuWidth]="hintsMenuWidth"></dropdown>
    </div>
      <div class="options">
        <div class="option">
          <ng-container *ngIf="(productType|async) == ProductType.PerforatedSheets">
            <mat-checkbox id="PerforationArea" class="base-checkbox topbar-checkbox" (change)="onPerforationAreaStatusChange($event)" [checked]="perforationAreaStatus | async">
                {{translate("Perforation Area")}}
            </mat-checkbox>
          </ng-container>
        </div>
        <div class="option">
          <mat-checkbox id="GridStatus" class="base-checkbox topbar-checkbox" [checked]="gridStatus | async" (change)="onGridStatusChange($event)">
            {{translate("Grid")}}
          </mat-checkbox>
        </div>
        <div class="option">
          <mat-checkbox id="HelpLineStatus" class="base-checkbox topbar-checkbox" [checked]="helpLineStatus | async" (change)="onHelpLineStatusChange($event)">
            {{translate("Help Lines")}}
          </mat-checkbox>
        </div>
        <div class="option">
          <mat-checkbox id="ClosestSegmentStatus" class="base-checkbox topbar-checkbox" [checked]="closestSegmentStatus | async" (change)="onClosestSegmentStatusChange($event)">
            {{translate("Closest Segment")}}
          </mat-checkbox>
        </div>
        <div class="option">
          <mat-checkbox id="MeasurementStatus" class="base-checkbox topbar-checkbox" [checked]="measurementStatus | async" (change)="onMeasurementStatusChange($event)">
            {{translate("Measurements")}}
          </mat-checkbox>
        </div>
        <div class="option">
          <ng-container *ngIf="(productType|async) == ProductType.PerforatedSheets">
            <mat-checkbox id="MountingsStatus" class="base-checkbox topbar-checkbox" [checked]="mountingsStatus | async" (change)="onMountingsStatusChange($event)">
              {{translate("Mountings")}}
            </mat-checkbox>
          </ng-container>
        </div>
      </div>
      <div>
        <color-picker></color-picker>
      </div>
  </div>
</ng-container>
