import {Component, HostListener, Input, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {
  getClosestSegmentVisibilityState,
  getHintStatus,
  getMountingsStatus,
  getPerforationAreaStatus,
  getSnapGridStatus,
  getSnapHelpLineStatus,
  getSnapMeasurementStatus,
  getStep,
  MevacoState,
  isTrustedOrganizationUnit, logoUrl, getProductType
} from '../../store/reducers';
import {Observable, of} from 'rxjs';
import {
  SetMeasurementVisibility,
  SetPerforationAreaVisibility,
  SetSnapOptionEdge,
  SetSnapOptionGrid,
  SetSnapOptionHelpLine,
  SetSnapOptionMountings,
  SetSnapOptionNode
} from '../../store/actions/snap-options.actions';
import {SetClosestSegmentVisiblity, SetHintStatus} from '../../store/actions/drawing.actions';
import {View3dProvider} from '../../providers/view3d.provider';
import {ProductType, Step} from '../../model/product-configuration/product-configuration.model';
import {Dropdown, DropdownOption} from '../dropdown/dropdown.component';
import {TranslationProvider} from '../../providers/translation.provider';
import {HintStatus} from '../../model/hint.model';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ActivatedRoute} from "@angular/router";
import {map, tap} from "rxjs/operators";
import {SetAppMode} from "../../store/actions";
import {AppMode} from "../../model/app-mode.enum";
import * as jwtDecode from 'jwt-decode';
import { environment } from 'environments/environment';

@Component({
  selector: 'top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css'],
  animations: [
    trigger('openClose', [
      state('open', style({
        transform: 'translateX(0)',
      })),
      state('closed', style({
        transform: 'translateX(280px)',
      })),
      transition('open <=> closed', [
        animate('0.5s')
      ])
    ])
    ]
})
export class TopBarComponent implements OnInit {
  public helpLineStatus: Observable<boolean>;
  public gridStatus: Observable<boolean>;
  public mountingsStatus: Observable<boolean>;
  public closestSegmentStatus: Observable<boolean>;
  public measurementStatus: Observable<boolean>;
  public perforationAreaStatus: Observable<boolean>;
  public productType = this.store.pipe(select(getProductType));
  public readonly ProductType = ProductType;

  private mouseDown: boolean;
  private jwtPayload =  this.activatedRoute.queryParams
    .pipe(
      map(params => {
        const jwt = Object.keys(params)[0];
        const payload: any = jwtDecode(jwt);
        return payload;
      })
    )
  public step: Observable<Step>;
  public mode$ = this.jwtPayload
    .pipe(
      map(payload => payload.mode || 'design'),
      tap((mode) => {
        this.store.dispatch(new SetAppMode(mode === 'design' ? AppMode.editor : AppMode.viewer));
      })
    );
  public Step = Step;
  public hints: Dropdown;
  private timer: any;
  private zoomSpeed = 10;
  private hintStatusObs: Observable<HintStatus>;
  @Input() configuratorOpen: boolean;

  public hintsMenuWidth: number;
  public environment = environment;
  public logoUrl = this.store.pipe( select(logoUrl) );


  @HostListener('window:resize', ['$event']) onResize(event) {
    if(event.target.innerWidth >= 1024 && event.target.innerWidth < 1664) {
      this.hintsMenuWidth = 195;
    } else if(event.target.innerWidth >= 1664 && event.target.innerWidth < 1904) {
      this.hintsMenuWidth = 225;
    } else if(event.target.innerWidth >= 1904) {
      this.hintsMenuWidth = 240;
    }
  }

  constructor(private store: Store<MevacoState>,
              private view3dProvider: View3dProvider,
              private translationProvider: TranslationProvider,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    if(window.innerWidth >= 1024 && window.innerWidth < 1664) {
      this.hintsMenuWidth = 195;
    } else if(window.innerWidth >= 1664 && window.innerWidth < 1904) {
      this.hintsMenuWidth = 225;
    } else if(window.innerWidth >= 1904) {
      this.hintsMenuWidth = 240;
    }
    this.step = this.store.pipe(select(getStep));
    this.gridStatus = this.store.pipe(select(getSnapGridStatus));
    this.helpLineStatus = this.store.pipe(select(getSnapHelpLineStatus));
    this.closestSegmentStatus = this.store.pipe(select(getClosestSegmentVisibilityState));
    this.measurementStatus = this.store.pipe(select(getSnapMeasurementStatus));
    this.mountingsStatus = this.store.pipe(select(getMountingsStatus));
    this.perforationAreaStatus = this.store.pipe(select(getPerforationAreaStatus));
    this.hintStatusObs = this.store.pipe(select(getHintStatus));
    // this.hints = this.createDropDown(
    //   'Hints',
    //   this.store.select(state => state.model.drawing.hintOption) as Observable<string>,
    //   this.getOptions(),
    //   (v) => new HintDropdownLabelChange(v)
    // );

    this.hints = new Dropdown(
      'Hints',
      this.getOptions(),
      this.hintStatusObs,
      (v) => this.onHintStatusChange(v)
    );
  }

  private getOptions(): Observable<DropdownOption[]> {
    return of([
      new DropdownOption('text', this.translate('Hints sticky'), true, HintStatus.Sticky),
      new DropdownOption('text', this.translate('Hints enabled'), true, HintStatus.Enabled),
      new DropdownOption('text', this.translate('Hints disabled'), true, HintStatus.Disabled)
    ]);

  }

  onEdgeStatusChange(event: any) {
    this.store.dispatch(new SetSnapOptionEdge(event.source.checked));
  }

  onNodeStatusChange(event: any) {
    this.store.dispatch(new SetSnapOptionNode(event.source.checked));
  }

  onGridStatusChange(event: any) {
    this.store.dispatch(new SetSnapOptionGrid(event.source.checked));
  }

  onHelpLineStatusChange(event: any) {
    this.store.dispatch(new SetSnapOptionHelpLine(event.source.checked));
  }

  onClosestSegmentStatusChange(event: any) {
    this.store.dispatch(new SetClosestSegmentVisiblity(event.source.checked));
  }

  onMeasurementStatusChange(event: any) {
    this.store.dispatch(new SetMeasurementVisibility(event.source.checked));
  }

  onMountingsStatusChange(event: any) {
    this.store.dispatch(new SetSnapOptionMountings(event.source.checked));
  }

  onPerforationAreaStatusChange(event: any) {
    this.store.dispatch(new SetPerforationAreaVisibility(event.source.checked));
  }




  // createDropDown(name: string, value: Observable<string>, options: Observable<DropdownOption[]>, actionFactory: (value: string) => Action) {
  //   return new Dropdown(
  //     name,
  //     options,
  //     value,
  //     (value) => this.store.dispatch(actionFactory(value))
  //   );
  // }

  onHintStatusChange(content: any) {
    if (content === HintStatus.Enabled) {
      this.store.dispatch(new SetHintStatus(HintStatus.Enabled));
    } else if (content === HintStatus.Disabled) {
      this.store.dispatch(new SetHintStatus(HintStatus.Disabled));
    } else if (content === HintStatus.Sticky) {
      this.store.dispatch(new SetHintStatus(HintStatus.Sticky));
    }
  }

  zoomToFit() {
    this.view3dProvider.zoomToFitPlate();
  }

  zoomIn() {
    if (!this.timer) {
      this.timer = setInterval(() => {
        this.view3dProvider.camera.radius -= 0.001 * this.zoomSpeed;
      }, 10);
    }
  }

  zoomOut() {
    if (!this.timer) {
      this.timer = setInterval(() => {
        this.view3dProvider.camera.radius += 0.001 * this.zoomSpeed;
      }, 10);
    }
  }

  stopAction() {
    clearInterval(this.timer);
    this.timer = null;
  }

  translate(text: string, module: string = 'configurator') {
    return this.translationProvider.translate(text, module);
  }
}
