import {MinValue, ShapeWithHoles} from '../shape-with-holes';
import {HelpLine} from '../help-line.model';
import {AngleMeasurementModel, MeasurementModel} from 'webcad/models';
import {MountingSave} from '../mounting.model';
import {PointNode} from '../point-node.model';
import {PerforationAreaModel} from '../perforation-area.model';
import {PerforationModel} from '../perforation.model';
import {BendingLine} from '../bending-line.model';
import {Aabb3} from 'webcad/math/aabb3';
import {Polyline} from '../polyline.model';
import {TemplateName} from '../../components/templates/template-name';
import {Segment} from 'webcad/models';
import {Aabb2, Vector2} from 'webcad';

export enum ElementType {
  standardFormat = 'StandardFormat',
  standardWidth = 'StandardWidth',
  individual = 'Individual'
}

export interface Element {
  uuid?: string;
  type: ElementType;
  templateName: TemplateName;
  boundary: Segment[];
  position: number;
  quantity: number;
  form?: string;
  a: number;
  aIst: number;
  b: number;
  bIst: number;
  e1: string;
  e1ist: string;
  e2: string;
  e2ist: string;
  f1: string;
  f1ist: string;
  f2: string;
  f2ist: string;
  lfbIst: string;
  lflIst: string;
  openMeshE: string;
  openMeshF: string;
  toleranceWidth: number;
  toleranceLength: number;
  label: string;
  note: string;
  unperforated: boolean;
  posibleCoil: string;
  posiblePlate: string;
  shapes: string;
  nodes: PointNode[];
  verticesIndexes: string;
  visualizationShape: string;
  previewImageId: string;
  shape?: ShapeWithHoles;
  perforationAreas?: PerforationAreaModel[];
  cutShape?: ShapeWithHoles;
  helpLines: HelpLine[];
  measurements: MeasurementModel[];
  angleMeasurements: AngleMeasurementModel[];
  mountings: MountingSave[];
  perforationAutoCenter: boolean;
  // perforation: PerforationModel;
  area?: number;
  minRadius: MinValue;
  minParallelEdgesDistance: MinValue;
  minMarginDistance: MinValue;
  minDistanceBetweenPerforationAndBending?: MinValue;
  minDistanceBetweenMountingAndBending?: MinValue;
  isShapeSimpleRect?: boolean;
  isPerforationSimpleRect?: boolean;
  // name: string;
  // noteActive: boolean;
  minMountingHolesDistance: MinValue;
  minMountingHoleEdgeDistance: MinValue;
  minOutsideAngle: MinValue;
  numberOfArcs: number;
  minDistanceBetweenEdges: MinValue;
  minDistanceBetweenMountingAndPerforatedArea: MinValue;
  possibleAllAcross: boolean;
  minDistanceBetweenPerforationAreas: MinValue;
  removedMeasurements?: MeasurementModel[];
  cutoutCount?: number;
  mountingCount?: number;
  minInsideAngle?: MinValue;
  minSegmentLength?: MinValue;
  breakLines: BendingLine[];
  bendingLinesDistances: number[];
  // possiblePerforationZones: ShapeWithHoles[];
  aabb?: Aabb3;
  minLegLength?: MinValue;
  length3d?: number;
  width3d?: number;
  height3d?: number;
  importId?: number;
  perfactionOffset?: Vector2;
  patternOffset?: Vector2;
  patternGrids?: PatternGrid[];
  minCutoutLength?: MinValue;

  //side?: 'ridge side'|'visible side'|'ridge inside'|'ridge outside'|'not ridge inside'|'not ridge outside';
}

export interface PatternGrid {
  deltaX: number;
  deltaY: number;
  startX: number;
  startY: number;
  countX: number;
  countY: number;
}
