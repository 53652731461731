import {Injectable} from '@angular/core';
import {createEffect} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {
  getCurrentPosition,
  getProductConfiguration,
  getProductType, getSelectedExpandedMetalSize, getSelectedExpandedMetalSizeDecode, getSelectedExpandedMetalTypeOfCut,
  getShapeWithHoles,
  MevacoState
} from '../reducers';
import {combineLatest, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {ProductType} from '../../model/product-configuration/product-configuration.model';
import {SetExpandedMetalCutShape} from '../actions';
import {PlateService} from '../../services/plate.service';
import {createRectangle, ShapeWithHoles} from '../../model/shape-with-holes';
import {aabbOfPolyline} from 'webcad';

@Injectable()
export class ExpandedMetalCutShapeEffects {
  constructor(
    private plateService: PlateService,
    private store: Store<MevacoState>
  ) {}

  readonly cutShape = createEffect(() =>
    combineLatest([
      this.store.select(getProductType),
      this.store.select(getSelectedExpandedMetalSizeDecode),
      this.store.select(getSelectedExpandedMetalTypeOfCut),
      this.store.select(getShapeWithHoles),
      this.store.select(getCurrentPosition),
    ]).pipe( switchMap(([productType, size, typeOfCut, shapeWithHoles, position]) => {
      if (productType === ProductType.ExtendedMetals && size && typeOfCut && shapeWithHoles?.conture?.length > 0 && shapeWithHoles.aabb) {
        const aabb = shapeWithHoles.aabb;
        let a = (aabb.max.y - aabb.min.y) * 1000;
        let b = (aabb.max.x - aabb.min.x) * 1000;
        const fa = size.a;
        let aIst = a;
        switch (typeOfCut) {
          case 'C':
          case 'E':
            aIst = +(Math.floor(a / (fa / 2)) * (fa / 2)).toFixed(0);
            break;
          case 'G':
          case 'H':
            aIst = +(Math.floor(a / (fa)) * (fa)).toFixed(0);
            break;
        }
        let bIst = b;
        const fb = size.b;
        switch (typeOfCut) {
          case 'A':
          case 'B':
          case 'C':
            bIst = +(Math.floor(b / (fb / 2)) * (fb / 2)).toFixed(0);
            break;
          case 'G':
          case 'H':
            bIst = +(Math.floor(b / (fb)) * (fb)).toFixed(0);
            break;
        }

        aIst /= 1000;
        bIst /= 1000;
        a /= 1000;
        b /= 1000;
        const ad = (a - aIst) / 2;
        const bd = (b - bIst) / 2;

        const conturesIst = createRectangle({ x: aabb.min.x + bd, y: aabb.min.y + ad }, { x: aabb.max.x - bd, y: aabb.max.y - ad });
        const aabbIst = aabbOfPolyline(conturesIst);
        const boundary: ShapeWithHoles = {
          conture: conturesIst,
          holes: [],
          aabb: aabbIst,
          area: bIst * bIst,
        };


        return this.plateService.updatePlateShape({
          shapesWithHoles: [shapeWithHoles],
          boundary: [boundary]
        }).pipe(map(response => new SetExpandedMetalCutShape(
          response.data?.length === 1 ? response.data[0] : null, position
        )));
      } else {
        return of(
          new SetExpandedMetalCutShape(
            null, position
          )
        );
      }
    })));

}
