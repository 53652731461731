import {Drawing} from '../../model/drawing.model';
import {PolylineViewModel} from '../../model/view-model/polylines.viewModel';

export class ExpmetCutModelProvider {
  private model: Drawing;
  private viewModel: PolylineViewModel;

  getViewModel(newModel: Drawing): PolylineViewModel {
    if (!newModel || !newModel.showExpandedMetalShape || !newModel.plate || !newModel.plate.cutShapeWithHoles ) {
      this.viewModel = null;
    } else if (
      !this.model ||
      this.model.plate.cutShapeWithHoles !== newModel.plate.cutShapeWithHoles ||
      this.model.showExpandedMetalShape !== newModel.showExpandedMetalShape
    ) {
      const polyline = [newModel.plate.cutShapeWithHoles.conture];
      for (let i = 0; i < newModel.plate.cutShapeWithHoles.holes.length; i++) {
        polyline.push( newModel.plate.cutShapeWithHoles.holes[i] );
      }
      this.viewModel = {polyline: polyline, color: newModel.showExpandedMetalShape === "cut"  ? '#1e0a30' : '#6e6e6e'};
    }
    this.model = newModel;
    return this.viewModel;
  }

}

export class ExpmetDesiredShapeModelProvider {
  private model: Drawing;
  private viewModel: PolylineViewModel;

  getViewModel(newModel: Drawing): PolylineViewModel {
    if (!newModel || newModel.showExpandedMetalShape !== 'cut' || !newModel.plate || !newModel.plate.shapeWithHoles ) {
      this.viewModel = null;
    } else if (
      !this.model ||
      this.model.plate.shapeWithHoles !== newModel.plate.shapeWithHoles
    ) {
      const polyline = [newModel.plate.shapeWithHoles.conture];
      for (let i = 0; i < newModel.plate.shapeWithHoles.holes.length; i++) {
        polyline.push( newModel.plate.shapeWithHoles.holes[i] );
      }
      this.viewModel = {polyline: polyline, color: '#6e6e6e'};
    }
    this.model = newModel;
    return this.viewModel;
  }

}
