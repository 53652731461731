import {ExpandedMetalVisualizerModel} from '../expanded-metal.vizualizer';
import {MevacoPage} from '../../model/mevaco-page.model';
import {
  getExpandedMetalGltfHash,
  ProductType,
  Step
} from '../../model/product-configuration/product-configuration.model';
import {ExpandedMetalModeToolModel} from '../../model/tool/expanded-metal-mode-tool.model';

export class ExpandedMetalViewModelProvider {
  private viewModel: ExpandedMetalVisualizerModel = {
    gltfId: null,
    shape: null,
    patternGrids: undefined,
    hexColorString: '#B0B0B0',
    toolOffset: null,
    toleranceLengthConture: 0,
    toleranceWidthConture: 0,
    toleranceLengthHole: 0,
    toleranceWidthHole: 0,

  };

  getViewModel(newModel: MevacoPage): ExpandedMetalVisualizerModel {
    const show = newModel.productConfiguration.productType === ProductType.ExtendedMetals && (newModel.productConfiguration.step === Step.pattern || newModel.productConfiguration.step === Step.design || newModel.productConfiguration.step === Step.cockpit);
    if ( !show ) {
      return null;
    }

    let toleranceLengthC = newModel.drawing?.toleranceLength || 0.5;
    let toleranceWidthC = newModel.drawing?.toleranceWidth || 0.5;
    let toleranceLengthH = toleranceLengthC;
    let toleranceWidthH = toleranceWidthC;

    if (newModel.productConfiguration.configuration.waterjetMode > 0 && newModel.productConfiguration.extendedMetal.size && newModel.productConfiguration.step === Step.pattern) {
      const size = newModel.productConfiguration.extendedMetal.size;
      const s1 = size.split('|');
      const s2 = s1[0].split(' x ');
      if (newModel.productConfiguration.configuration.waterjetMode  === 3) {
        toleranceLengthC = Number(s2[1]) / 2;
        toleranceWidthC = Number(s2[0]) / 2;
        toleranceLengthH = Number(s2[1]) / 2;
        toleranceWidthH = Number(s2[0]) / 2;
      }
      if (newModel.productConfiguration.configuration.waterjetMode  === 1) {
        toleranceLengthC = Number(s2[1]) / 2;
        toleranceWidthC = Number(s2[0]) / 2;
      }
      if (newModel.productConfiguration.configuration.waterjetMode  === 2) {
        toleranceLengthH = Number(s2[1]) / 2;
        toleranceWidthH = Number(s2[0]) / 2;
      }
    }

    const viewModel: ExpandedMetalVisualizerModel = show ? {
      gltfId: getExpandedMetalGltfHash(newModel.productConfiguration),
      shape: newModel.drawing?.plate?.cutShapeWithHoles,
      patternGrids: newModel.drawing?.patternGrid,
      hexColorString: newModel.drawing?.plate?.hexColorString || '#B0B0B0',
      toolOffset: newModel.drawing?.tool?.name === 'ExpandedMetalTool' ? (newModel.drawing?.tool as ExpandedMetalModeToolModel).offset : null,
      toleranceLengthConture: toleranceLengthC,
      toleranceWidthConture: toleranceWidthC,
      toleranceLengthHole: toleranceLengthH,
      toleranceWidthHole: toleranceWidthH,
    } : null;

    // not loaded yet
    if ( !newModel.gltfs[viewModel.gltfId] ) {
      return null;
    }

    if ( this.viewModel.gltfId !== viewModel.gltfId || this.viewModel.shape !== viewModel.shape || this.viewModel.hexColorString !== viewModel.hexColorString || this.viewModel.patternGrids !== viewModel.patternGrids || this.viewModel.toolOffset !== viewModel.toolOffset) {
      this.viewModel = viewModel;
    }
    return this.viewModel;
  }
}
