import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { Vector2 } from "webcad/math";
import { HtmlLabelModel } from "webcad/models/html-label.model";
import {
  MevacoState,
  getActiveElement,
  getRuleActionsForActivePlate,
} from "../../store/reducers";

@Component({
  selector: "errors-visualizer",
  templateUrl: "./errors-visualizer.component.html",
  styleUrls: ["./errors-visualizer.component.css"],
})
export class ErrorsVisualizerComponent implements OnInit, OnDestroy {
  public errorModels: Observable<HtmlLabelModel[]>;

  constructor(private store: Store<MevacoState>) {}

  ngOnInit() {
    this.errorModels = combineLatest([
      this.store.pipe(select(getRuleActionsForActivePlate)),
      this.store.pipe(select(getActiveElement)),
    ]).pipe(
      map(([rules, element]) => {
        const models: HtmlLabelModel[] = [];
        if (!!rules) {
          for (const r of rules) {
            let position: Vector2 = null;
            switch (r.fieldName) {
              case "element.minRadius.value":
                position =
                  element && element.minRadius && element.minRadius.position;
                break;
              case "element.minMarginDistance.value":
                position =
                  element &&
                  element.minMarginDistance &&
                  element.minMarginDistance.position;
                break;
              case "element.minParallelEdgesDistance.value":
                position =
                  element &&
                  element.minParallelEdgesDistance &&
                  element.minParallelEdgesDistance.position;
                break;
              case "element.minDistanceBetweenMountingAndPerforatedArea.value":
                position =
                  element &&
                  element.minDistanceBetweenMountingAndPerforatedArea &&
                  element.minDistanceBetweenMountingAndPerforatedArea.position;
                break;
              case "element.minDistanceBetweenEdges.value":
                position =
                  element &&
                  element.minDistanceBetweenEdges &&
                  element.minDistanceBetweenEdges.position;
                break;
              case "element.minOutsideAngle.value":
                position =
                  element &&
                  element.minOutsideAngle &&
                  element.minOutsideAngle.position;
                break;
              case "element.minMountingHoleEdgeDistance.value":
                position =
                  element &&
                  element.minMountingHoleEdgeDistance &&
                  element.minMountingHoleEdgeDistance.position;
                break;
              case "element.minMountingHolesDistance.value":
                position =
                  element &&
                  element.minMountingHolesDistance &&
                  element.minMountingHolesDistance.position;
                break;
              case "element.minCutoutLength.value":
                position =
                  element &&
                  element.minCutoutLength &&
                  element.minCutoutLength.position;
                break;
            }
            if (!!position) {
              models.push({
                value: r.message,
                offset: { x: 0, y: 0 },
                visible: true,
                placement: {
                  position: position,
                  angle: 0,
                },
              });
            }
          }
        }
        return models;
      })
    );
  }

  ngOnDestroy() {}
}
